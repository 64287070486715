
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ClaimsTables() {

  const [annual, setAnnual] = useState(true)
  const navigate = useNavigate()
  const pdfNavigate = (fileName, fileUrl) => {

    navigate(`/forms/${fileName}`, { state: { fileUrl: fileUrl } })
  }

  return (
    <div className='mb-24'>

      {/* Pricing toggle */}
      <div className="flex justify-center items-center space-x-4 sm:space-x-7 mb-24">

        {/* <div className="form-switch shrink-0">
          <input type="checkbox" id="toggle" className="sr-only" checked={annual} onChange={() => setAnnual(!annual)} />
          <label className="bg-slate-700" htmlFor="toggle">
            <span className="bg-slate-300 border-8 border-slate-500" aria-hidden="true"></span>
            <span className="sr-only">Pay annually</span>
          </label>
        </div>
        <div className="text-sm text-slate-500 font-medium min-w-[8rem]">Pay Yearly <span className="text-emerald-500">(-20%)</span></div> */}
      </div>

      <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-2 lg:gap-6 items-start lg:max-w-none pt-4">

        {/* Pricing table 1 */}
        <div className="relative flex flex-col h-full px-6 py-5 bg-white shadow-lg" data-aos="fade-up">
          <div className="mb-4 pb-4 border-b border-slate-200">
            {/* <div className="text-lg font-semibold text-slate-800 mb-1">Advanced</div> */}
            <div className="inline-flex items-baseline mb-3">
              BRM Claims Kit
            </div>
            {/* <div className="text-slate-500">  Download Form</div> */}
          </div>

          <div className="font-medium mb-3">Summary of claims kit</div>

          <div className="p-3 rounded bg-slate-50">
            <button onClick={() => {
              pdfNavigate('Claims-kit', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-Claims-Kit-Fillable_Rebranded-1.pdf?alt=media&token=0970489c-a225-4e88-bb97-8bcfb82f570e')
            }} className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full group" href="#0">
              Download Claims Kit <span className="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
            </button>
          </div>
        </div>



        {/* Pricing table 3 */}
        <div className="relative flex flex-col h-full px-6 py-5 bg-white shadow-lg" data-aos="fade-up" data-aos-delay="200">
          <div className="mb-4 pb-4 border-b border-slate-200">
            {/* <div className="text-lg font-semibold text-slate-800 mb-1">Advanced</div> */}
            <div className="inline-flex items-baseline mb-3">
              TPA APPROVAL QUESTIONNAIRE
            </div>
            {/* <div className="text-slate-500">  Download Form</div> */}
          </div>
          <div className="font-medium mb-3">summary</div>

          <div className="p-3 rounded bg-slate-50">
            <button onClick={() => {
              pdfNavigate('TPA-approval', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-TPA-Approval-Questionnaire_Rebranded.pdf?alt=media&token=82136972-4967-4f4d-ae69-da049999972b')
            }} className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full group" href="#0">
              Download Form <span className="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
            </button>
          </div>
        </div>

      </div>

    </div>
  )
}